import { AfterViewInit, Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RepositoryService } from './shared/repository.service';
declare function sidebar(): any;
declare function menucollepse(): any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'demo-aximus'
  public today: number = Date.now();

  constructor(public repoService: RepositoryService) {
    
  }
  ngAfterViewInit() {
    sidebar();
    menucollepse();
  }
}
